import { render, staticRenderFns } from "./orderTotal.vue?vue&type=template&id=7aca4541&scoped=true"
import script from "./orderTotal.vue?vue&type=script&lang=js"
export * from "./orderTotal.vue?vue&type=script&lang=js"
import style0 from "./orderTotal.vue?vue&type=style&index=0&id=7aca4541&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aca4541",
  null
  
)

export default component.exports