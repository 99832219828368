<template>
  <App
    title="订单总计"
    left-arrow
  >
    <div class="card">
      <Field
        label="订单总计"
        class="card-title"
        input-align="right"
        readonly
      />
      <Field
        v-for="(item, index) in order"
        :key="index"
        :label="item.label"
        :value="orderInfo[item.key]"
        input-align="right"
        readonly
      />
    </div>
    <div class="card">
      <Field
        label="收款总计"
        class="card-title"
        input-align="right"
        readonly
      />
      <Field
        v-for="(item, index) in proceeds"
        :key="index"
        :label="item.label"
        :value="orderInfo[item.key]"
        input-align="right"
        readonly
      />
    </div>
  </App>
</template>

<script>
import Field from '_c/dynamicForm/field'

export default {
  name: 'orderTotal',
  components: { Field },
  data () {
    return {
      orderInfo: {},
      order: [
        { label: '车身零售价', key: 'price' },
        { label: '选装价格总计', key: 'featurePrice' },
        { label: '统一销售价格', key: 'acutalPrice' },
        { label: '折扣合计', key: 'discountUniteTotal' },
        { label: '整车开票金额', key: 'invoicePrice' },
        { label: '整车描述', key: 'vehicleDesc' }
      ],
      proceeds: [
        { label: '应收总金额', key: 'invoicePrice' },
        { label: '已收总金额', key: 'receivedAmount' },
        { label: '未收总金额', key: 'gatheringAmount' }
      ]
    }
  },
  methods: {
  },
  mounted () {
    this.orderInfo = this.$route.query || {}
    console.log(this.orderInfo)
  }
}
</script>
<style lang="less" scoped>
.card {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 72px;
  }

  .card-title {
    /deep/ .van-field__label {
      font-size: 0.373333rem;
      font-weight: bold;
      color: #000;
      width: 3rem;
    }

    .icon-edit {
      width: 48px;
      height: 48px;
    }
  }

  /deep/ .slide-operator {
    height: 1.146667rem;
    line-height: 1.146667rem;
    background-color: #fff;
  }

  .link-text {
    margin-left: 16px;
  }

  button {
    margin-left: 10px;
    padding: 0 24px;
    height: 53px;
    line-height: 53px;
    border: 2px solid #999;
    border-radius: 149px;
    font-size: 26px;
    color: #999;
    background-color: transparent;
  }

  /deep/ .slide .van-cell__title {
    width: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  /deep/ .slide .van-cell__value input {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
}
</style>
